exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-copyright-policy-tsx": () => import("./../../../src/pages/copyright-policy.tsx" /* webpackChunkName: "component---src-pages-copyright-policy-tsx" */),
  "component---src-pages-get-support-tsx": () => import("./../../../src/pages/get-support.tsx" /* webpackChunkName: "component---src-pages-get-support-tsx" */),
  "component---src-pages-how-it-works-for-manager-tsx": () => import("./../../../src/pages/how-it-works-for-manager.tsx" /* webpackChunkName: "component---src-pages-how-it-works-for-manager-tsx" */),
  "component---src-pages-how-it-works-for-vendor-tsx": () => import("./../../../src/pages/how-it-works-for-vendor.tsx" /* webpackChunkName: "component---src-pages-how-it-works-for-vendor-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-usage-tsx": () => import("./../../../src/pages/terms-of-usage.tsx" /* webpackChunkName: "component---src-pages-terms-of-usage-tsx" */),
  "component---src-pages-verified-tsx": () => import("./../../../src/pages/verified.tsx" /* webpackChunkName: "component---src-pages-verified-tsx" */)
}

